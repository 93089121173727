import { configureStore } from '@reduxjs/toolkit'
import langSlice from './slices/langSlice'
import authSlice from './slices/auth'
import assistantsSlice from './slices/assistants'
export const store = configureStore({
    reducer: {
        lang: langSlice,
        auth: authSlice,
        assistants: assistantsSlice,
    },
})