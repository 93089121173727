import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    isAuth: false,
    user: {},
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authChange: (state, action) => {
            state.isAuth = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { authChange, setUser } = authSlice.actions

export default authSlice.reducer